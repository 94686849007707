import React from 'react'

export default function Values() {
  return (
    <section id="values" className="values">

      <div className="container">

        <header className="section-header">
          <p>SOLUTIONS WE PROVIDE</p>
        </header>

        <div className="row">

          <div className="col-lg-4">
            <div className="box">
              <img src="assets/img/values-1.png" className="img-fluid" alt=""/>
              <h3>All under one roof</h3>
              <p>We manage all your Influencer Marketing Requirements.</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="box">
              <img src="assets/img/values-2.png" className="img-fluid" alt=""/>
              <h3>We boost your profile</h3>
              <p>Help you be seen in more companies searches, higher up than non boosted profiles.</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="box">
              <img src="assets/img/values-3.png" className="img-fluid" alt=""/>
              <h3>We save your time</h3>
              <p>By connecting you to your value brands.</p>
            </div>
          </div>

        </div>

      </div>

    </section>
  )
}
