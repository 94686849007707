import React, { useEffect, useState } from 'react';

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header id="header" className={`header fixed-top ${isScrolled ? 'header-scrolled' : ''}`}>
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

        <a href="/" className="logo d-flex align-items-center">
          <img src="assets/img/logo.png" alt=""/>
          <span>CollabHunt-S</span>
        </a>

        <nav id="navbar" className="navbar">
          <ul>
            <li><a className="nav-link scrollto" href="/register">Register</a></li>
            <li><a className="getstarted scrollto" href="/login">Login</a></li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>

      </div>
    </header>
  )
}
