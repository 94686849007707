import React from 'react'

export default function Footer() {
  return (
    <footer id="footer" className="footer">

      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <a href="/" className="logo d-flex align-items-center">
                <img src="assets/img/logo.png" alt=""/>
                <span>CollabHunt</span>
              </a>
              <p>Helping companies to connect to influencers.</p>
              <div className="social-links mt-3">
                <a href="https://twitter.com/collabhunt" className="twitter"><i className="bi bi-twitter"></i></a>
                <a href="https://www.facebook.com/collabhuntofficial/" className="facebook"><i className="bi bi-facebook"></i></a>
                <a href="https://www.instagram.com/collabhunt/" className="instagram"><i className="bi bi-instagram"></i></a>
                <a href="https://www.linkedin.com/company/collabhunt" className="linkedin"><i className="bi bi-linkedin"></i></a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </footer>
  )
}
