import React, { useState } from 'react';

export default function LoginForm() {
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform login logic here, such as sending a request to the server
    // with the email and password for authentication.

    // Reset the form after login logic is completed
    setPhone('');
    setOtp('');
  };

  return (
    <section id="hero" className="hero d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <div className="col-6 ml-2">
              <header className="section-header">
                <p>Login</p>
              </header>
              <form onSubmit={handleSubmit}>
                <div className="row gy-4">

                  <div className="col-md-12">
                    <input type="text" className="form-control" placeholder="Your Phone" value={phone} onChange={handlePhoneChange} required/>
                  </div>

                  <div className="col-md-12">
                  <input type="text" className="form-control" placeholder="Otp" value={otp} onChange={handleOtpChange} required/>
                  </div>

                  <div className="col-md-12 text-center">
                    <button type="submit" className="form-control btn btn-primary" >Login</button>
                  </div>

                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-6 hero-img">
            <img src="assets/img/hero-img.png" className="img-fluid" alt=""/>
          </div>

        </div>
      </div>
    </section>
  )
}
