import React from 'react'

export default function About() {
  return (
    <section id="about" className="about">

      <div className="container">
        <div className="row gx-0">

          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <div className="content">
              <h2>Come and connect</h2>
              <p>We help companies to growing there customer by social media marketing.</p>
            </div>
          </div>

          <div className="col-lg-6 d-flex align-items-center">
            <img src="assets/img/about.jpg" className="img-fluid" alt=""/>
          </div>

        </div>
      </div>

    </section>
  )
}
