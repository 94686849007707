import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Footer from './components/Footer'
import Values from './components/Values'

function App() {
  return (
    <div>
      <Header/>
      <Hero/>
      <About/>
      <Values/>
      <Footer/>
    </div>
  );
}

export default App;
