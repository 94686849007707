import React from 'react';
import Header from './Header';
import Footer from './Footer'
import SignupForm from './SignupForm';

export default function SignupPage() {
  return (
    <div>
      <Header/>
      <SignupForm/>
      <Footer/>
    </div>
  )
}
